import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { RulesTable, QSharedFilters } from '@kw/quokka-ui';

import { QTableRow } from '../../Q-Components/QTableRow';
import { QTable } from '../../Q-Components/QTable';
import { QTableBody } from '../../Q-Components/QTableBody';
import { QTableCell } from '../../Q-Components/QTableCell';
import { QTabContext } from '../../Q-Components/QTabContext';
import { QTabList } from '../../Q-Components/QTabList';
import { QTab } from '../../Q-Components/QTab';
import { QTabPanel } from '../../Q-Components/QTabPanel';

import { MaliciousScoreChart } from './MaliciousScoreChart';
import { PermissionsChart } from './PermissionsChart';
import { RulesChart } from './RulesChart';
import { getEvidencesCallback, getRulesFiltersConfig } from './helper';

export const OverviewTab = ({
  aggregateData,
  ruleSummaries = [],
  farmAppUuid,
  maliciousResult,
}) => {
  const [filteredRuleSummaries, setFilteredRuleSummaries] = useState([]);
  const [activeTab, setActiveTab] = useState('rules');
  const [permissionFindings, setPermissionFindings] = useState([]);
  const [filteredPermissionFindings, setFilteredPermissionFindings] = useState(
    [],
  );
  const [libraryRelatedFindings, setLibraryRelatedFindings] = useState([]);

  useEffect(() => {
    setPermissionFindings(
      ruleSummaries.filter(({ rule }) => rule.tool.includes('permoid')),
    );
    setLibraryRelatedFindings(
      ruleSummaries.filter(({ rule }) => rule.id.includes('loads-library')),
    );
  }, [ruleSummaries]);

  const handleTabChange = (event, newTabValue) => {
    setActiveTab(newTabValue);
  };

  const handleRulesFilteredData = filteredData => {
    setFilteredRuleSummaries(filteredData);
  };

  const handlePermissionsFilteredData = filteredData => {
    setFilteredPermissionFindings(filteredData);
  };

  const filtersConfig = getRulesFiltersConfig(ruleSummaries);
  const permissionFiltersConfig = getRulesFiltersConfig(ruleSummaries, [
    'category',
    'compliance',
  ]);

  return (
    <Grid container direction="column" rowSpacing="40px">
      <Grid container item direction="row" rowSpacing={2} columnSpacing={2}>
        {aggregateData?.levelSummary && (
          <Grid item xs={12} sm={6} xl={3}>
            <RulesChart rulesSummary={aggregateData.levelSummary} />
          </Grid>
        )}
        {aggregateData?.permissionSummary && (
          <Grid item xs={12} sm={6} xl={3}>
            <PermissionsChart
              permissionsSummary={aggregateData.permissionSummary}
            />
          </Grid>
        )}
        {maliciousResult && (
          <Grid item xs={12} sm={6} xl={3}>
            <MaliciousScoreChart maliciousResult={maliciousResult} />
          </Grid>
        )}
      </Grid>
      <Grid container item direction="column">
        <QTabContext value={activeTab}>
          <QTabList
            onChange={handleTabChange}
            sx={{
              '.MuiTabs-indicator': {
                top: 0, // Moves the indicator to the top
                bottom: 'unset', // Removes default bottom positioning
              },
              '.MuiTab-root': {
                paddingTop: '16px',
                fontSize: '14px',
                fontWeight: '600',
                color: '#757575',
                minWidth: '226px',
              },
              '.Mui-selected': {
                backgroundColor: '#FAFAFA',
                color: '#000000',
                fontWeight: '800',
              },
              backgroundColor: '#EEEEEE',
              borderBottom: 'none',
            }}
          >
            <QTab label="Rules" value="rules" />
            <QTab label="Permissions" value="permissions" />
            <QTab label="Libraries & Frameworks" value="libraries" />
          </QTabList>
          <QTabPanel value="rules" sx={{ padding: 0 }}>
            <QTable data-testid="rules-table">
              <QTableBody>
                <QTableRow hoverDisabled>
                  <QTableCell sx={{ padding: '0px' }}>
                    <QSharedFilters
                      filtersConfig={filtersConfig}
                      originalData={ruleSummaries}
                      onFilteredData={handleRulesFilteredData}
                    />
                  </QTableCell>
                </QTableRow>
                <RulesTable
                  ruleSummaries={filteredRuleSummaries}
                  uuid={farmAppUuid}
                  getEvidencesCallback={getEvidencesCallback}
                />
              </QTableBody>
            </QTable>
          </QTabPanel>
          <QTabPanel value="permissions">
            <QTable data-testid="permissions-table">
              <QTableBody>
                <QTableRow hoverDisabled>
                  <QTableCell sx={{ padding: '0px' }}>
                    <QSharedFilters
                      filtersConfig={permissionFiltersConfig}
                      originalData={permissionFindings}
                      onFilteredData={handlePermissionsFilteredData}
                    />
                  </QTableCell>
                </QTableRow>
                <RulesTable
                  ruleSummaries={filteredPermissionFindings}
                  uuid={farmAppUuid}
                  getEvidencesCallback={getEvidencesCallback}
                />
              </QTableBody>
            </QTable>
          </QTabPanel>
          <QTabPanel value="libraries">
            <QTable data-testid="libraries-frameworks-table">
              <QTableBody>
                <RulesTable
                  ruleSummaries={libraryRelatedFindings}
                  uuid={farmAppUuid}
                  getEvidencesCallback={getEvidencesCallback}
                />
              </QTableBody>
            </QTable>
          </QTabPanel>
        </QTabContext>
      </Grid>
    </Grid>
  );
};

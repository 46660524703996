import React from 'react';

import { QTypography } from '../../Q-Components/QTypography';
import { QUncontrolledTooltip } from '../../Q-Components/QUncontrolledTooltip';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import i18n from '../../../localization/i18n';
import { EditableInput } from '../../EditableInput';
import util from '../../../logic/util';

export const GroupSsoConfig = ({ group, updateGroupConfig }) => {
  const removeCertificate = index => {
    const updatedCerts = group.ssoCerts.filter((_, i) => i !== index);
    updateGroupConfig({ ssoCerts: updatedCerts });
  };

  const updateSsoCerts = async newSSOCert => {
    newSSOCert = util.normalizePEM(newSSOCert);
    const updatedCerts = group.ssoCerts
      ? [...group.ssoCerts, newSSOCert]
      : [newSSOCert];
    await updateGroupConfig({ ssoCerts: updatedCerts });
  };

  const updateLoginUrls = async newLoginUrl => {
    if (!group.logoutUrl) {
      await updateGroupConfig({
        loginUrl: newLoginUrl,
        logoutUrl: newLoginUrl,
      });
    } else {
      await updateGroupConfig({ loginUrl: newLoginUrl });
    }
  };

  return (
    <>
      <QTypography variant="h5Bold" color="#000000">
        {i18n.t('SSO Configuration')}
      </QTypography>
      <br />
      <br />
      <QTypography variant="h5Regular" color="#000000">
        {i18n.t(
          'This section allows an admin to enable and alter the SAML SSO configuration for the group. A login URL and at least one certificate are required for SSO to be enabled.',
        )}
      </QTypography>
      <hr />
      <QTypography variant="h5Regular" color="#000000">
        {i18n.t('Add SSO Certificate')}
      </QTypography>
      <EditableInput
        id="group-certificate-input"
        initValue=""
        onSave={newSsoCert => updateSsoCerts(newSsoCert)}
        multiline
      />

      {group.ssoCerts?.length > 0 && (
        <QListGroup>
          {group.ssoCerts?.map((cert, index) => (
            <QListGroupItem
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                overflow: 'hidden',
              }}
              divider
            >
              {cert.length > 100 ? `${cert.substring(0, 100)}...` : cert}
              <div>
                <QUncontrolledTooltip
                  target="delete-certificate-icon"
                  placement="top"
                  title="Delete certificate"
                />
                <button
                  type="button"
                  className="icon-button"
                  onClick={() => removeCertificate(index)}
                  aria-label="Delete certificate"
                >
                  <i
                    id="delete-certificate-icon"
                    className="fa-regular fa-circle-xmark pointer"
                  />
                </button>
              </div>
            </QListGroupItem>
          ))}
        </QListGroup>
      )}

      <br />
      <QTypography variant="h5Regular" color="#000000">
        {i18n.t('Login Url:')}
      </QTypography>
      <EditableInput
        id="group-loginUrl-input"
        initValue={group.loginUrl ?? ''}
        onSave={newLoginUrl => updateLoginUrls(newLoginUrl)}
      />
      <br />
      <QTypography variant="h5Regular" color="#000000">
        {i18n.t('Logout Url:')}
      </QTypography>
      <EditableInput
        id="group-logoutUrl-input"
        initValue={group.logoutUrl ?? ''}
        onSave={newLogoutUrl => updateGroupConfig({ logoutUrl: newLogoutUrl })}
      />
    </>
  );
};

import React, { useState, useEffect } from 'react';

import { QInputGroup } from './Q-Components/QInputGroup';
import { QInput } from './Q-Components/QInput';
import { QInputIconWrapper } from './Q-Components/QInputIconWrapper';
import { QButton } from './Q-Components/QButton';
import { QButtonGroup } from './Q-Components/QButtonGroup';

export const EditableInput = ({
  label,
  placeholder = '',
  initValue = '',
  onSave,
  multiline = false,
}) => {
  const [value, setValue] = useState(initValue);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const input = multiline
    ? {
        type: 'textarea',
        sx: {
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            minHeight: '40px',
            height: 'auto',
          },
        },
      }
    : {
        type: 'text',
      };

  return (
    <span>
      <QInputGroup>
        <QInput
          multiline={multiline}
          type={input.type}
          sx={input.sx}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={e => setValue(e.target.value)}
          disabled={!isEditing}
          data-testid="test-input"
          InputProps={{
            readOnly: !isEditing,
            endAdornment: (
              <QInputIconWrapper position="end">
                {isEditing ? (
                  <QButtonGroup>
                    <QButton
                      color="primary"
                      onClick={() => {
                        setIsEditing(false);
                        onSave(value);
                      }}
                      aria-label="save-edit"
                      variant="filled"
                      style={{
                        minWidth: '0px',
                        padding: '8px 12px',
                      }}
                    >
                      <i
                        style={{
                          fontSize: '20px',
                        }}
                        className="fa-regular fa-floppy-disk"
                      />
                    </QButton>
                    <QButton
                      onClick={() => {
                        setIsEditing(false);
                        setValue(initValue);
                      }}
                      aria-label="cancel-editing"
                      variant="light"
                      style={{
                        minWidth: '0px',
                        padding: '8px 12px',
                      }}
                    >
                      <i
                        style={{
                          fontSize: '20px',
                        }}
                        className="fa-regular fa-xmark"
                      />
                    </QButton>
                  </QButtonGroup>
                ) : (
                  <QButton
                    onClick={() => setIsEditing(true)}
                    color="primary"
                    aria-label="start-editing"
                    variant="basic"
                    style={{
                      minWidth: '0px',
                      background: 'none',
                      padding: '0px',
                    }}
                  >
                    <i
                      style={{
                        fontSize: '24px',
                      }}
                      className="fa-regular fa-edit"
                    />
                  </QButton>
                )}
              </QInputIconWrapper>
            ),
          }}
        />
        <QInputIconWrapper>
          {isEditing ? (
            <span>
              <QButton
                color="primary"
                onClick={() => {
                  setIsEditing(false);
                  onSave(value);
                }}
                aria-label="save-edit"
              >
                <i className="fa-regular fa-floppy-disk" />
              </QButton>
              <QButton
                onClick={() => setIsEditing(false)}
                aria-label="cancel-editing"
              >
                <i className="fa-regular fa-xmark" />
              </QButton>
            </span>
          ) : (
            <QButton
              onClick={() => setIsEditing(true)}
              color="primary"
              aria-label="start-editing"
            >
              <i className="fa-regular fa-edit" />
            </QButton>
          )}
        </QInputIconWrapper>
      </QInputGroup>
    </span>
  );
};

import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Grid from '@mui/material/Grid';
import {
  RulesTable,
  MaliciousScoreFactorsChart,
  ThreatTypeDistributionChartCard,
  ProfileCategoriesBarChart,
  QSharedFilters,
} from '@kw/quokka-ui';

import { QContainer } from '../../Q-Components/QContainer';
import { QTypography } from '../../Q-Components/QTypography';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QDivider } from '../../Q-Components/QDivider';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QTable } from '../../Q-Components/QTable';
import { QTableBody } from '../../Q-Components/QTableBody';
import { QTableRow } from '../../Q-Components/QTableRow';
import { QTableCell } from '../../Q-Components/QTableCell';
import {
  getMaliciousThreatLevel,
  getMaliciousThreatTypes,
} from '../../../logic/dopplerUtil';

import { BehaviorsChartCard } from './BehaviorsChartCard';
import { PermissionsChart } from './PermissionsChart';
import { getEvidencesCallback, getRulesFiltersConfig } from './helper';

export function MaliciousTab({
  ruleSummaries,
  app,
  maliciousResult,
  aggregateData,
}) {
  const [maliciousRulesSummaries, setMaliciousRulesSummaries] = useState([]);
  const [filteredMaliciousBehaviors, setFilteredMaliciousBehaviors] = useState(
    [],
  );
  const farmAppUuid = app?.farm_app.uuid;

  const handleFilteredData = filteredData => {
    setFilteredMaliciousBehaviors(filteredData);
  };

  const filtersConfig = getRulesFiltersConfig(
    maliciousRulesSummaries,
    'compliance',
  );

  const { maliciousReportAndroid, maliciousReportIos, sarifReport } =
    useFlags();

  useEffect(() => {
    setFilteredMaliciousBehaviors(maliciousRulesSummaries);
    setMaliciousRulesSummaries(
      ruleSummaries.filter(
        ruleSummary =>
          (ruleSummary.rule?.tool === 'pulsar' ||
            ruleSummary.rule?.tool === 'pulsar-ios' ||
            ruleSummary.rule?.tool === 'permoid') &&
          ruleSummary.rule?.id?.startsWith('profile'),
      ),
    );
  }, []);

  const maliciousScore = getMaliciousThreatLevel(maliciousResult, true);
  const maliciousThreatTypes = getMaliciousThreatTypes(maliciousResult, true);

  const platform = app?.farm_app.platform;
  const maliciousReportAndroidVisibility =
    maliciousReportAndroid && platform === 'android';
  const maliciousReportIosVisibility = maliciousReportIos && platform === 'ios';
  const maliciousReportVisibility =
    sarifReport &&
    (maliciousReportIosVisibility || maliciousReportAndroidVisibility);

  return (
    <QContainer
      fluid
      sx={{
        maxWidth: '100% !important',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
      }}
    >
      {maliciousReportVisibility ? (
        <>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4.5}>
              <MaliciousScoreFactorsChart
                score={maliciousScore}
                threatTypes={maliciousThreatTypes}
              />
            </Grid>
            <Grid item xs={4.5}>
              <ThreatTypeDistributionChartCard
                threatTypes={maliciousThreatTypes}
              />
            </Grid>
            <Grid item xs={3}>
              <PermissionsChart
                permissionsSummary={aggregateData?.permissionSummary}
              />
            </Grid>
            <Grid item xs={9}>
              <ProfileCategoriesBarChart
                results={maliciousRulesSummaries.map(
                  behavior => behavior.details,
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BehaviorsChartCard
                results={maliciousRulesSummaries.map(
                  behavior => behavior.details,
                )}
              />
            </Grid>
          </Grid>
          <QCard
            style={{
              padding: '0',
              marginBottom: '18px',
              boxShadow: 'none',
              borderRadius: '0',
            }}
          >
            <QCardHeader disableTypography title="Behaviors" />
            <QDivider />
            <QCardBody sx={{ padding: '0px' }}>
              <QDivider sx={{ borderColor: '#E0E0E0' }} />
              <QTable data-testid="rules-table">
                <QTableBody>
                  <QTableRow hoverDisabled>
                    <QTableCell sx={{ padding: '0px' }}>
                      <QSharedFilters
                        filtersConfig={filtersConfig}
                        originalData={maliciousRulesSummaries}
                        onFilteredData={handleFilteredData}
                      />
                    </QTableCell>
                  </QTableRow>
                  <RulesTable
                    ruleSummaries={filteredMaliciousBehaviors}
                    uuid={farmAppUuid}
                    getEvidencesCallback={getEvidencesCallback}
                  />
                </QTableBody>
              </QTable>
            </QCardBody>
          </QCard>
        </>
      ) : (
        <QTypography>No permissions to view this page</QTypography>
      )}
    </QContainer>
  );
}
